<template>
  <RevContainer>
    <RevBreadcrumb
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="mobileRoutes"
      class="-ml-24 block md:hidden"
      data-qa="breadcrumb-mweb"
    />

    <RevBreadcrumb
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="desktopRoutes"
      class="hidden md:block"
      data-qa="breadcrumb-desktop"
    />
  </RevContainer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import type { Bread } from '@ds/components/Breadcrumb/Breadcrumb.vue'
import { RevContainer } from '@ds/components/Container'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './Navigation.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const desktopRoutes: Array<Bread> = [
  {
    link: resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF }),
    title: i18n(translations.myOrders),
  },
  {
    link: resolveLocalizedRoute({
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
    }),
    title: i18n(translations.refundRequest),
  },
]

const mobileRoutes: Array<Bread> = [
  {
    link: resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF }),
    title: i18n(translations.myOrders),
  },
  {
    link: resolveLocalizedRoute({
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
    }),
    title: i18n(translations.refundRequest),
  },
]
</script>
