import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  durationInMonths: {
    id: 'duration_in_months',
    defaultMessage: `{duration, plural,
        =1 {# month}
        other {# months}
       }`,
  },
  durationInYears: {
    id: 'duration_in_years',
    defaultMessage: `{duration, plural,
        =1 {# year}
        other {# years}
       }`,
  },
} as Record<string, I18nDefinition>
