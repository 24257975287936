import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './useFormatMonthsYearsDuration.translations'

export function useFormatMonthsYearsDuration() {
  const i18n = useI18n()

  function getFormattedDuration(monthsDuration: number) {
    if (monthsDuration % 12 || monthsDuration === 0) {
      return i18n(translations.durationInMonths, { duration: monthsDuration })
    }

    return i18n(translations.durationInYears, { duration: monthsDuration / 12 })
  }

  return { getFormattedDuration }
}
